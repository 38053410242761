class Client {

    constructor() {
        this.events = {};
    }

    setUrl(url) {
        this.url = url;
        this.ws = null;
    }

    connect() {
        setTimeout(() => {
            this.ws = new WebSocket(this.url);
            this.ws.addEventListener('open', () => {
                if(this.events['open']) this.events['open']();
            });
            this.ws.addEventListener('error', () => {
                // if(this.events['close']) this.events['close']();
            });
            this.ws.addEventListener('close', () => {
                if(this.events['close']) this.events['close']();
                this.connect();
            });
            this.ws.addEventListener('message', (message) => {
                if(!message.data) return;
                try {
                    message = JSON.parse(message.data);
                    if(this.events[message.action]) this.events[message.action](message.data);
                } catch(e) {

                }
                // if(this.events['close']) this.events['close']();
            });
        }, this.ws ? 3000 : 1);
    }

    send(action, body) {
        let message = {
            action: action,
            data: body
        }
        this.ws.send(JSON.stringify(message));
    }

    on(name, callback) {
        this.events[name] = callback;
    }


}


export default {
    install: (app, options) => {
        let client = new Client();
        app.config.globalProperties.$WebSocket = client;
    }
}



// require('nativescript-websockets');



// const connect = (callback) => {
//     this.connection = new WebSocket(process.env.WS_URL);

//     this.connection.addEventListener('open', () => {
//         callback(false, true);
//     });

//     this.connection.addEventListener('error', () => {
//         callback(true, false);
//     });

//     this.connection.addEventListener('close', () => {
//         callback(true, false);
//     });

//     this.connection.addEventListener('message', onMessage);
// }


// const onMessage = (evt) => {
//     if(!this.events) this.events = {};
//     let message = JSON.parse(evt.data);

//     let callback = this.events[message.event];
//     if(callback) callback(message.data);
// }

// const on = (event, callback) => {
//     // creating events array
//     if(!this.events) this.events = {};
//     this.events[event] = callback;
// }



// const auth = (data) => {
//     this.connection.send(JSON.stringify({
//         event: 'auth',
//         data: data
//     }));
// }




// module.exports = {
//     connect,
//     on,
//     auth
// }