import axios from 'axios';


axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:4000';


axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('token');
    if(token) config.headers.authorization = token;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});