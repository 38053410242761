import moment from 'moment';

export default {
    install: (app, options) => {
        app.mixin({
            methods: {
                priceConverter(value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
        });

    }
  }