<template>
    <div>
        <router-view></router-view>
        <ModalCaller/>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                darkModeOn: true
            }
        },
        methods: {
            
        },
        
    }
</script>

<style lang="scss">
// css here

</style>