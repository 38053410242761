import { createStore } from 'vuex'

export default createStore({
    state: {
        user: null,
        token: null,
        axiosLoaders: [],
        loadManager: {
            viewLoad: null
        },
        numberOfModals: 0,
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        token: (state) => {
            return state.token;
        },
        axiosLoaders: (state) => {
            return state.axiosLoaders;
        },
        loadManager: (state) => {
            return state.loadManager;
        },
        numberOfModals: (state) => {
            return state.numberOfModals;
        }
    },
    mutations: {
        token: (state, payload) => {
            state.token = payload;
        },
        user: (state, payload) => {
            state.user = payload;
        },
        numberOfModals: (state, payload) => {
            state.numberOfModals = payload;
        }
    },
    actions: {

    },
    modules: {

    }
})
