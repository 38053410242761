import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios';


let app = createApp(App);



import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();



// components
import Input from './components/Input'
app.component("Input", Input);

import ModalSimple from './components/ModalSimple'
app.component("ModalSimple", ModalSimple);


import PageLoader from './components/PageLoader'
app.component("PageLoader", PageLoader);

// plugins
import PriceConverter from './plugins/PriceConverter'
import ModalCaller from './plugins/ModalCaller'
import WSClient from './plugins/WebSocket'
app.use(PriceConverter);
app.use(ModalCaller);
app.use(WSClient);


// directives
import tooltip from "./directives/tooltip.js";
import "@/assets/scss/tooltip.css";
app.directive("tooltip", tooltip);



// mixins
import axiosLoader from './mixins/axiosLoader'
app.mixin(axiosLoader);

import copyToClipboard from './mixins/copyToClipboard'
app.mixin(copyToClipboard);


// use
import vClickOutside from "click-outside-vue3"
app.use(vClickOutside);

app.use(store).use(router).mount('#app')

